import React from 'react'
// @ts-ignore
import BlockContent from '@sanity/block-content-to-react'

import Heading from '../components/heading'
import Text from '../components/text';
import PokemonPreview from '../components/pokemon/pokemon-preview';
import { PokemonReference } from '../models/pokemon';

const serializers = {
    types: {
        code: (props: any) => (
            <pre data-language={props.node.language}>
                <code>{props.node.code}</code>
            </pre>
        ),
        pokemon: ({ node }: { node: PokemonReference }) => (
            <PokemonPreview pokemonRef={node} mr={2}/>
        ),
        block: (props: any) => {
            const { style = 'normal' } = props.node;

            if (/^h\d/.test(style)) {
                const level = Number(style.replace(/[^\d]/g, ''))
                return <Heading as={style} mt={9} mb={4} fontSize={9 - level}>{props.children}</Heading>
            }

            if (style === 'blockquote') {
                return <blockquote>- {props.children}</blockquote>
            }

            if (style === 'normal') {
                return <Text fontSize={3} mb={4}>{props.children}</Text>
            }

            // Fall back to default handling
            return BlockContent.defaultSerializers.types.block(props)

        }
    }
}

export default serializers