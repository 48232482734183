import React, { FC } from "react"
import { graphql, PageProps } from "gatsby"
import Img from "gatsby-image"
import dayjs from 'dayjs'
// @ts-ignore
import BlockContent from '@sanity/block-content-to-react'


import SEO from "../components/seo"
import Card from "../components/card"
import Heading from "../components/heading"
import Text from "../components/text"
import ResearchTask from "../components/research-tasks/research-task"
import Box from "../components/box"
import EventBonus from "../components/event/event-bonus"
import Wrapper from "../containers/wrapper"
import serializers from "../helpers/serializers"
import { Event } from "../models/event"

interface Props extends PageProps {
  data: {
    sanityEvent: Event
  }
}

const EventTemplate: FC<Props> = ({ data }) => {
  const { sanityEvent: event } = data;

  return (
    <>
      <SEO title={`${event.name} Pokemon Go`} />
      {event.image && <Box mb={4} mt={-6}>
        <Img fluid={event.image.asset.fluid} />
      </Box>
      }
      <Wrapper>
        <Heading as="h1" fontSize={7} mt={6} mb={2}>{event.name}</Heading>
        <Text fontSize={2} mb={4}>{dayjs(event.dateStart).format("ddd, MMM D, h:mm A")} - {dayjs(event.dateEnd).format("ddd, MMM D, h:mm A")}</Text>

        {event._rawIntro && <BlockContent blocks={event._rawIntro} serializers={serializers} />}

        {event.bonuses?.length > 0 &&
          <Box my={8}>
            <Heading as="h3" fontSize={3} mb={3}>Bonuses</Heading>
            {event.bonuses.map((bonus: any, index: number) => (
              <EventBonus key={`${event.slug.current}-bonus-${index}`} bonus={bonus} isCompressed={false} />
            ))}
          </Box>
        }

        {event._rawBody && <BlockContent blocks={event._rawBody} serializers={serializers} />}
      </Wrapper>
    </>
  )
}

export const query = graphql`
  query($id: String!) {
    sanityEvent(id: {eq: $id}) {
      ...EventFields
    }
  }
`

export default EventTemplate
